import React from "react"
import AppFooter from "../AppFooter/AppFooter"
import CurveContainer from "../CurveContainer/CurveContainer"
import AcademicDashboard1 from "../../images/academicDashboard1.png"
import AcademicDashboard2 from "../../images/academicDashboard2.png"
import AcademicDashboard3 from "../../images/academicDashboard3.png"
import AcademicDashboard4 from "../../images/academicDashboard4.png"
import AcademicMeetingImage from "../../images/academicMeetingImage.png"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"
import AppHeader from "../V2/AppHeader"
import AdvocateSection from "../DomeHomePage/AvocateSection/AdvocateSection"
import HomePageBackgroundContainer from "../V2/HomePageBackgroundContainer"
import AcademicFormWithCard from "../SignUp/AcademicsSignUp/AcademicFormWithCard"

const DomeAcademicsPage: React.FC = () => {
  return (
    <section className="w-full bg-white">
      <AppHeader />
      <HomePageBackgroundContainer
        className="bg-academic-blue pt-32"
        mainHeading={"How does it work?"}
        buttonText="Get Started"
        onButtonClick={() =>
          document
            .getElementById("academic_form")
            ?.scrollIntoView({ behavior: "smooth" })
        }
      />
      <article>
        <div className="relative -mt-40">
          <CurveContainer color="light">
            <div className="flex flex-col items-center max-w-3xl mx-auto px-5 -mt-40">
              <TextTypography
                className="text-2xl md:text-3xl mt-6 text-center"
                color={TextColor.BLACK}
                fontStyle={FontStyle.REGULAR}
              >
                Experts from academic institutions, think tanks, and foundations
                can share insights with decision-makers working on issues in
                real time. Only on Dome, are you shielded from any and all
                personal attacks.
              </TextTypography>
            </div>
          </CurveContainer>
        </div>
      </article>
      <article className="mt-14">
        <div className="container mx-auto flex justify-center">
          <TextTypography
            fontStyle={FontStyle.BOLD}
            className="text-center text-5xl"
          >
            Academics
          </TextTypography>
        </div>
        <AdvocateSection
          imageSrc={AcademicDashboard1}
          imagePosition="right"
          description="Academics register for Dome and create an Academic Registry Page where they share basic information about themselves."
          title="Register"
        />
        <AdvocateSection
          imageSrc={AcademicDashboard2}
          description="Get right to your point. The Dome audience wants your knowledge and perspective. Keep it current and keep it short."
          title="Academics share their expertise"
        />
        <div className="mt-14">
          <img src={AcademicMeetingImage} alt="academic_meeting_image" />
        </div>
        <AdvocateSection
          imageSrc={AcademicDashboard3}
          imagePosition="right"
          description="Engage with an audience who can take action. Receive questions and feedback from decision-makers working in real time on issues that matter."
          title="Reach an audience in a position to affect change"
        />
        <AdvocateSection
          imageSrc={AcademicDashboard4}
          description="Dome’s unique metrics give you the information you need to assess and improve your briefings."
          title="Gain metrics to improve your briefings"
        />
      </article>
      <article className="mt-14">
        <HomePageBackgroundContainer
          mainHeading={"Get Involved without \n Getting Tracked"}
          className="pb-44"
        />
        <article>
          <div className="relative -mt-40">
            <CurveContainer color="light" hideBottomCurve>
              <div>
                <div
                  className="flex flex-col max-w-4xl mx-auto px-5"
                  id="academic_form"
                >
                  <TextTypography
                    className="text-2xl md:text-3xl mt-6 text-center"
                    color={TextColor.BLACK}
                    fontStyle={FontStyle.ITALIC}
                  >
                    Academics have policy expertise. Decision-makers need that.
                    They have questions and you have answers.
                  </TextTypography>
                  <TextTypography
                    className="text-2xl md:text-3xl mt-10 text-center"
                    color={TextColor.BLACK}
                    fontStyle={FontStyle.ITALIC}
                  >
                    Dome makes it easy to engage with your most powerful
                    audience.
                  </TextTypography>
                </div>
                <div className="pb-20">
                  <AcademicFormWithCard />
                </div>
                <AppFooter />
              </div>
            </CurveContainer>
          </div>
        </article>
      </article>
    </section>
  )
}

export default DomeAcademicsPage
