import React from "react"
import Card from "../../SharedResources/Card/Card"
import AcademicsSignUpForm from "./AcademicsSignUpForm"
import academicImage from "./../../../images/academic.svg"

const AcademicFormWithCard: React.FC = () => {
  return (
    <div className="flex justify-center px-5">
      <div className="flex flex-col items-center md:items-stretch md:flex-row max-w-6xl mt-10 w-full">
        <Card
          short_note="Are you ready to present to an audience who can take action?"
          content={{
            card_title: "Dome\\n Academic Registry",
            logo: {
              url: academicImage,
            },
            card_description:
              "Experts from universities,\\n think tanks, and foundations \\nare eligible",
            join_title:
              "Are you ready to present to an audience who can take action?",
            paragraph_1:
              "<b>Reach</b> decision-makers with your policy knowledge and recommendations",
            paragraph_2:
              "<b>Engage</b> decision-makers via Dome’s unique feature that gives them anonymity and you access",
            paragraph_3:
              "<b>Measure & improve</b> your performance with Dome’s unique metrics & feedback reports",
          }}
        />

        <div className="w-full bg-white rounded-2xl mt-10 md:mt-0 md:ml-8 p-10 shadow-md">
          <AcademicsSignUpForm />
        </div>
      </div>
    </div>
  )
}

export default AcademicFormWithCard
