import * as React from "react"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
import DomeAcademicsPage from "../components/DomeAcademicsPage/DomeAcademicsPage"

const Academics = () => {
  return (
    <main>
      <FaviconHeader />
      <DomeAcademicsPage />
    </main>
  )
}

export default Academics
