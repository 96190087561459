import React from "react"
import { TextTypography } from "../../SharedResources/Typography/TextTypography/TextTypography"
import { FontStyle } from "../../SharedResources/Typography/typography.service"

interface AdvocateSectionProps {
  imagePosition?: "right" | "left"
  title: string
  description: string
  imageSrc: string
}
const AdvocateSection: React.FC<AdvocateSectionProps> = ({
  imageSrc,
  imagePosition = "left",
  title,
  description,
}) => {
  return (
    <div className="flex justify-center mt-16 container relative z-10">
      <div
        className={`flex ${
          imagePosition === "left" ? "flex-row-reverse lg:space-x-reverse lg:-ml-16" : "flex-row"
        }  flex-wrap w-full lg:flex-no-wrap justify-center lg:space-x-16 `}
      >
        <div className="flex flex-col justify-center w-full mx-4 mb-16 lg:max-w-[296px] lg:mb-0 lg:mx-0">
          <TextTypography
            className="text-[28px] leading-[32px] "
            fontStyle={FontStyle.SEMIBOLD}
          >
            {title}
          </TextTypography>
          <TextTypography
            className="text-xl mt-6"
            fontStyle={FontStyle.REGULAR}
          >
            {description}
          </TextTypography>
        </div>
        <div className="flex flex-col justify-center max-w-[600px] px-4">
          <img
            className="w-full h-full mx-auto"
            alt="Event Detail"
            src={imageSrc}
          />
        </div>
      </div>
    </div>
  )
}

export default AdvocateSection
